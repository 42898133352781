import { createApp } from 'vue'
import { RouterView } from 'vue-router'

import i18n from './i18n'
import router from './router'

import Btn from './components/ui/Btn.vue'
import Alert from './components/ui/Alert.vue'

import 'tailwindcss/tailwind.css'

const app = createApp(RouterView)
  .use(i18n)
  .use(router)

app.component('Btn', Btn)
app.component('Alert', Alert)

app.mount('#app')
