<template>
  <Loader v-if="loading" />

  <div v-else class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-20 w-auto" :src="state.customer.logo.url" alt="Workflow">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {{ $t('title') }}
      </h2>
      <p v-if="state.product.address" class="mt-2 text-center text-sm text-gray-600">
        {{ $t('for') }}
        <a :href="state.product.url" target="_blank" class="font-medium text-indigo-600 hover:text-indigo-500">
          {{ state.product.address }}
        </a>
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <router-view />
      </div>

      <Bullets class="mt-10" />
    </div>

    <div class="fixed top-3 right-3 text-xs">
      <Btn size="xs" :variant="localeVariant('nl')" @click="changeLocale('nl')">
        NL
      </Btn>
      <Btn size="xs" :variant="localeVariant('en')" class="ml-1" @click="changeLocale('en')">
        EN
      </Btn>
    </div>
  </div>
</template>

<script>
import Loader from './ui/Loader.vue'
import Bullets from './ui/Bullets.vue'
import state from '../state'

export default {
  components: { Loader, Bullets },
  props: {
    customer: String,
    slug: String
  },
  setup () {
    return { state }
  },
  data () {
    return {
      loading: false
    }
  },
  created () {
    this.loading = true

    state.load(this.customer, this.slug).finally(() => {
      this.loading = false
    })
  },
  methods: {
    changeLocale (locale) {
      this.$root.$i18n.locale = locale
      state.viewingRequest.locale = locale
    },
    localeVariant (locale) {
      return this.$root.$i18n.locale === locale
        ? 'primary'
        : null
    }
  }
}
</script>

<i18n lang="yaml">
nl:
  title: Plan een bezichtiging
  for: voor
en:
  title: Plan a viewing
  for: for
</i18n>
