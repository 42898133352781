<template>
  <button type="button" class="inline-flex justify-center items-center border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50" :class="[variantClasses, sizeClasses]">
    <component :is="iconLeftComponent" v-if="iconLeft" class="mr-2 -ml-0.5 h-5 w-5" aria-hidden="true" />
    <slot />
    <component :is="iconRightComponent" v-if="iconRight" class="ml-2 -mr-0.5 h-5 w-5" aria-hidden="true" />
  </button>
</template>

<script>
export default {
  props: {
    iconLeft: String,
    iconRight: String,
    variant: String,
    size: String
  },
  computed: {
    variantClasses () {
      switch (this.variant) {
        case 'primary': return 'border-transparent text-white bg-indigo-600 hover:bg-indigo-700'
        default: return 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
      }
    },
    sizeClasses () {
      switch (this.size) {
        case 'xs': return 'px-2.5 py-1.5 text-xs rounded'
        default: return 'px-4 py-2 text-sm rounded-md'
      }
    },
    iconLeftComponent () {
      return require('@heroicons/vue/outline')[`${this.iconLeft}Icon`]
    },
    iconRightComponent () {
      return require('@heroicons/vue/outline')[`${this.iconRight}Icon`]
    }
  }
}
</script>
