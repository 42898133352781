<template>
  <div class="block rounded-lg border border-gray-300 bg-white shadow-sm cursor-pointer hover:border-gray-400 focus:outline-none">
    <Disclosure>
      <DisclosureButton class="w-full">
        <div class="text-sm text-left px-6 py-4">
          <p class="font-medium text-gray-900 first-letter:uppercase">
            {{ date(viewingDate.start, 'dddd D MMMM') }}
          </p>
          <p class="text-gray-500">
            {{ availableCount }} {{ $t('available') }}
          </p>
        </div>
      </DisclosureButton>

      <DisclosurePanel class="px-6 pb-4">
        <Btn v-for="(slot, i) in viewingDate.time_slots" :key="i" size="xs" class="mr-1 mb-1" :variant="state.isSelected(slot) ? 'primary' : null" :disabled="slot.request" @click="state.select(slot)">
          {{ date(slot.start, 'H:mm') }} - {{ date(slot.end, 'H:mm') }}
        </Btn>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import date from '../../util/date'
import state from '../../state'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel
  },
  props: {
    viewingDate: Object
  },
  setup () {
    return { state, date }
  },
  computed: {
    availableCount () {
      return this.viewingDate.time_slots.filter(s => !s.request).length
    }
  }
}
</script>

<i18n lang="yaml">
nl:
  available: beschikbare tijden
en:
  available: slots available
</i18n>
