export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beschikbare tijden"])}
      },
      "en": {
        "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slots available"])}
      }
    }
  })
}