<template>
  <h3 class="text-lg leading-6 font-medium text-gray-900">
    {{ $t('title') }}
  </h3>
  <p class="mt-1 text-sm text-gray-500">
    {{ $t('subtitle') }}
  </p>

  <Alert v-if="!state.futureViewingDates.length" class="mt-5">
    {{ $t('no_slots_available') }}
    {{ $t('contact', { email: state.customer.email_address, phone: state.customer.phone_number }) }}
  </Alert>

  <DateSelector v-for="viewingDate in state.futureViewingDates" v-else :key="viewingDate.id" :viewing-date="viewingDate" class="mt-5" />

  <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
    <Btn class="hidden sm:inline-flex" @click="cancel">
      {{ $t('cancel') }}
    </Btn>
    <Btn variant="primary" icon-right="ArrowRight" class="w-full" :disabled="!state.isSelectionMade" @click="next">
      {{ $t('next') }}
    </Btn>
  </div>
</template>

<script>
import state from '../../state'
import DateSelector from '../ui/DateSelector.vue'

export default {
  components: { DateSelector },
  setup () {
    return { state }
  },
  methods: {
    next () {
      this.$router.push({ name: 'info' })
    },
    cancel () {
      window.location.href = state.customer.website
    }
  }
}
</script>

<i18n lang="yaml">
nl:
  title: 1. Kies een datum en tijd
  subtitle: Selecteer de dag en het tijdstip waarop jij de woning wilt gaan bezichtigen.
  no_slots_available: Momenteel zijn er geen bezichtigingsmomenten bekend/beschikbaar.
  contact: Neem voor meer informatie contact op via {email} of {phone}.
  cancel: Annuleren
  next: Jouw gegevens
en:
  title: 1. Choose a date and time
  subtitle: Select the day and time you want to view the property.
  no_slots_available: Currently there are no viewing slots known/available.
  contact: For more information, contact us at {email} or {phone}.
  cancel: Cancel
  next: Your details
</i18n>
