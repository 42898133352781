export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezichtiging ingepland!"])},
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt voor je interesse in deze woning."])},
        "date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We zien je graag a.s. ", _interpolate(_named("date")), " om ", _interpolate(_named("time")), " uur."])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing scheduled!"])},
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your interest in this property."])},
        "date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We look forward to seeing you on ", _interpolate(_named("date")), " at ", _interpolate(_named("time")), "."])}
      }
    }
  })
}