<template>
  <div>
    <div class="flex items-center justify-between">
      <h2 class="text-sm font-medium text-gray-900">
        {{ question }}
      </h2>
    </div>

    <RadioGroup :model-value="modelValue" class="mt-2">
      <RadioGroupLabel class="sr-only">
        {{ question }}
      </RadioGroupLabel>
      <div class="grid grid-cols-3 gap-2 sm:grid-cols-6">
        <RadioGroupOption v-slot="{ active, checked }" as="template_true" :value="true" @click="update(true)">
          <div :class="[active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', checked ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50', 'cursor-pointer focus:outline-none border rounded-md py-1 px-3 flex items-center justify-center text-xs font-normal uppercase sm:flex-1']">
            <RadioGroupLabel as="p">
              {{ $t('yes') }}
            </RadioGroupLabel>
          </div>
        </RadioGroupOption>
        <RadioGroupOption v-slot="{ active, checked }" as="template_false" :value="false" @click="update(false)">
          <div :class="[active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', checked ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50', 'cursor-pointer focus:outline-none border rounded-md py-1 px-3 flex items-center justify-center text-xs font-normal uppercase sm:flex-1']">
            <RadioGroupLabel as="p">
              {{ $t('no') }}
            </RadioGroupLabel>
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>
  </div>
</template>

<script>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

export default {
  name: 'BinaryQuestion',
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption
  },
  props: {
    question: String,
    modelValue: Boolean
  },
  created () {
    if (!this.modelValue) {
      this.update(false)
    }
  },
  methods: {
    update (answer) {
      this.$emit('update:modelValue', answer)
    }
  }
}
</script>

<i18n lang="yaml">
nl:
  'yes': JA
  'no': NEE
en:
  'yes': 'YES'
  'no': 'NO'
</i18n>
