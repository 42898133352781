<template>
  <h3 class="text-lg leading-6 font-medium text-gray-900">
    {{ $t('title') }}
  </h3>
  <p class="mt-1 text-sm text-gray-500">
    {{ $t('subtitle') }}
  </p>

  <div class="grid grid-cols-6 gap-4 mt-4">
    <div class="col-span-6 sm:col-span-3">
      <label for="first-name" class="block text-sm font-medium text-gray-700">{{ $t('first_name') }}</label>
      <input id="first-name" v-model="contact.first_name" type="text" name="fname" autocomplete="given-name" :class="[inputClasses, { '!border-red-300': v$.contact.first_name.$error }]">
    </div>

    <div class="col-span-6 sm:col-span-3">
      <label for="last-name" class="block text-sm font-medium text-gray-700">{{ $t('last_name') }}</label>
      <input id="last-name" v-model="contact.last_name" type="text" name="lname" autocomplete="family-name" :class="[inputClasses, { '!border-red-300': v$.contact.last_name.$error }]">
    </div>

    <div class="col-span-6">
      <label for="email-address" class="block text-sm font-medium text-gray-700">{{ $t('email_address') }}</label>
      <input id="email-address" v-model="contact.email_address" type="email" name="email" autocomplete="email" :class="[inputClasses, { '!border-red-300': v$.contact.email_address.$error }]">
    </div>

    <div class="col-span-6">
      <label for="phone-number" class="block text-sm font-medium text-gray-700">{{ $t('phone_number') }}</label>
      <input id="phone-number" v-model="contact.phone_number" type="tel" name="phone" autocomplete="tel" :class="[inputClasses, { '!border-red-300': v$.contact.phone_number.$error }]">
    </div>

    <div class="col-span-6">
      <h4 class="text-base font-medium text-gray-700">
        {{ $t('address_title') }}
      </h4>
    </div>

    <div class="col-span-4">
      <label for="street" class="block text-sm font-medium text-gray-700">{{ $t('street') }}</label>
      <input id="street" v-model="contact.street" type="text" name="street" :class="[inputClasses, { '!border-red-300': v$.contact.street.$error }]">
    </div>

    <div class="col-span-2">
      <label for="house-number" class="block text-sm font-medium text-gray-700">{{ $t('house_number') }}</label>
      <input id="house-number" v-model="contact.house_number" type="text" name="housenumber" :class="[inputClasses, { '!border-red-300': v$.contact.house_number.$error }]">
    </div>

    <div class="col-span-2">
      <label for="postal-code" class="block text-sm font-medium text-gray-700">{{ $t('postal_code') }}</label>
      <input id="postal-code" v-model="contact.postal_code" type="text" name="postal" autocomplete="postal-code" :class="[inputClasses, { '!border-red-300': v$.contact.postal_code.$error }]">
    </div>

    <div class="col-span-4">
      <label for="city" class="block text-sm font-medium text-gray-700">{{ $t('city') }}</label>
      <input id="city" v-model="contact.city" type="text" name="city" autocomplete="address-level2" :class="[inputClasses, { '!border-red-300': v$.contact.city.$error }]">
    </div>
  </div>

  <Alert class="mt-5">
    {{ $t('choice', { date: date(state.viewingRequest.start, 'dddd D MMMM'), from: date(state.viewingRequest.start, 'H:mm'), to: date(state.viewingRequest.end, 'H:mm') }) }}
  </Alert>

  <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
    <Btn icon-left="ArrowSmLeft" class="hidden sm:inline-flex" @click="back">
      {{ $t('back') }}
    </Btn>
    <Btn variant="primary" icon-left="ArrowRight" class="w-full" :disabled="v$.$invalid || loading" :title="v$.$invalid ? 'Enkele gegevens zijn nog niet (correct) ingevuld' : null" @click="next">
      {{ skipQuestions ? $t('confirm') : $t('questions') }}
    </Btn>
  </div>
</template>

<script>
import state from '../../state'
import date from '../../util/date'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true }),
      state,
      date
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    contact () {
      if (Object.keys(state.viewingRequest.contact).length === 0) {
        state.viewingRequest.contact = {
          first_name: null,
          last_name: null,
          email_address: null,
          phone_number: null,
          street: null,
          house_number: null,
          postal_code: null,
          city: null
        }
      }

      return state.viewingRequest.contact
    },
    inputClasses () {
      return 'mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
    },
    skipQuestions () {
      return state.settings.skip_questions
    }
  },
  methods: {
    back () {
      this.$router.push({ name: 'date' })
    },
    next () {
      this.skipQuestions
        ? this.confirm()
        : this.questions()
    },
    questions () {
      this.$router.push({ name: 'questions' })
    },
    confirm () {
      this.loading = true

      state.sendViewingRequest()
        .then(() => {
          this.$router.push({ name: 'confirm' })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  validations () {
    return {
      contact: {
        first_name: { required },
        last_name: { required },
        email_address: { required, email },
        phone_number: { required },
        street: {},
        house_number: {},
        postal_code: {},
        city: {}
      }
    }
  }
}
</script>

<i18n lang="yaml">
nl:
  title: 2. Jouw gegevens
  subtitle: Vul je contactgegevens in, zodat we weten wie je bent.
  address_title: Jouw woonadres
  first_name: Voornaam
  last_name: Achternaam
  email_address: E-mailadres
  phone_number: Telefoonnummer
  street: Straat
  house_number: Huisnummer
  postal_code: Postcode
  city: Plaats
  choice: Je hebt gekozen voor {date} van {from} uur tot {to} uur.
  back: Terug
  questions: Laatste vragen
  confirm: Bevestigen
en:
  title: 2. Your details
  subtitle: Please enter your contact details, so we know who you are.
  address_title: Residential address
  first_name: First name
  last_name: Last name
  email_address: Email address
  phone_number: Phone number
  street: Street
  house_number: House number
  postal_code: Postal code
  city: City
  choice: You’ve selected {date} from {from} to {to}.
  back: Back
  questions: Final questions
  confirm: Confirm
</i18n>
