export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan een bezichtiging"])},
        "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voor"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan a viewing"])},
        "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])}
      }
    }
  })
}