export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Jouw gegevens"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je contactgegevens in, zodat we weten wie je bent."])},
        "address_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouw woonadres"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
        "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
        "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat"])},
        "house_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huisnummer"])},
        "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats"])},
        "choice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je hebt gekozen voor ", _interpolate(_named("date")), " van ", _interpolate(_named("from")), " uur tot ", _interpolate(_named("to")), " uur."])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
        "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste vragen"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Your details"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your contact details, so we know who you are."])},
        "address_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential address"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
        "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
        "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
        "house_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House number"])},
        "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "choice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You’ve selected ", _interpolate(_named("date")), " from ", _interpolate(_named("from")), " to ", _interpolate(_named("to")), "."])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
        "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final questions"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
      }
    }
  })
}