export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Kies een datum en tijd"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer de dag en het tijdstip waarop jij de woning wilt gaan bezichtigen."])},
        "no_slots_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momenteel zijn er geen bezichtigingsmomenten bekend/beschikbaar."])},
        "contact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neem voor meer informatie contact op via ", _interpolate(_named("email")), " of ", _interpolate(_named("phone")), "."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouw gegevens"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Choose a date and time"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the day and time you want to view the property."])},
        "no_slots_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently there are no viewing slots known/available."])},
        "contact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For more information, contact us at ", _interpolate(_named("email")), " or ", _interpolate(_named("phone")), "."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your details"])}
      }
    }
  })
}