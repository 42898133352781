import dayjs from 'dayjs'
import nl from 'dayjs/locale/nl'
import en from 'dayjs/locale/en'
import i18n from '../i18n'

const locales = { nl, en }

export default function (value, format) {
  dayjs.locale(locales[i18n.global.locale])

  return dayjs(value).format(format)
}
