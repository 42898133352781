export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEE"])}
      },
      "en": {
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YES"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])}
      }
    }
  })
}