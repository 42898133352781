<template>
  <nav v-if="currentStep !== 3" class="flex items-center justify-center" aria-label="Progress">
    <ol role="list" class="flex items-center space-x-5">
      <li v-for="step in steps" :key="step.name">
        <router-link v-if="step.status === 'complete'" :to="{name: step.route}" class="block w-2.5 h-2.5 bg-indigo-600 rounded-full hover:bg-indigo-900">
          <span class="sr-only">{{ step.name }}</span>
        </router-link>

        <router-link v-else-if="step.status === 'current'" :to="{name: step.route}" class="relative flex items-center justify-center" aria-current="step">
          <span class="absolute w-5 h-5 p-px flex" aria-hidden="true">
            <span class="w-full h-full rounded-full bg-indigo-200" />
          </span>
          <span class="relative block w-2.5 h-2.5 bg-indigo-600 rounded-full" aria-hidden="true" />
          <span class="sr-only">{{ step.name }}</span>
        </router-link>

        <router-link v-else :to="{name: step.route}" class="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400">
          <span class="sr-only">{{ step.name }}</span>
        </router-link>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  computed: {
    steps () {
      return [
        { name: '1. Kies datum en tijd', route: 'date', status: this.getState(1) },
        { name: '2. Jouw gegevens', route: 'info', status: this.getState(2) },
        { name: '3. Vragen', route: 'questions', status: this.getState(3) }
      ]
    },
    currentStep () {
      return this.$route.meta.step
    }
  },
  methods: {
    getState (step) {
      if (step === this.currentStep) {
        return 'current'
      } else if (step < this.currentStep) {
        return 'complete'
      } else {
        return 'upcoming'
      }
    }
  }
}
</script>
