import { reactive } from 'vue'
import api from './util/api'
import i18n from './i18n'
import dayjs from 'dayjs'

const state = {
  customer: reactive({}),
  product: reactive({}),
  viewingDates: reactive([]),
  viewingRequest: reactive({}),
  settings: reactive({}),

  get isSelectionMade () {
    return !!state.viewingRequest.start
  },

  get isValidEmail () {
    const regex = /\S+@\S+\.\S+/
    return regex.test(state.viewingRequest.email_address)
  },

  get futureViewingDates () {
    const now = dayjs()
    return state.viewingDates.filter(date => dayjs(date.end).isAfter(now))
  },

  load (customer, slug) {
    state.viewingRequest.locale = i18n.global.locale

    return api.get(`/planner/${customer}/${slug}`)
      .then((result) => {
        Object.assign(state.customer, result.data.customer)
        Object.assign(state.product, result.data.product)
        Object.assign(state.viewingDates, result.data.viewing_dates)
        Object.assign(state.settings, state.customer.viewing_planner_settings)
      })
  },

  sendViewingRequest () {
    return api.post('/planner/requests', state.viewingRequest)
  },

  select (slot) {
    state.viewingRequest.viewing_date_id = slot.viewing_date_id
    state.viewingRequest.start = slot.start
    state.viewingRequest.end = slot.end
    state.viewingRequest.contact = {}
    state.viewingRequest.answers = {}
  },
  isSelected (slot) {
    return state.viewingRequest.start === slot.start
  }
}

export default state
