import { createRouter, createWebHistory } from 'vue-router'
import state from './state'

import App from './components/App.vue'
import ChooseDate from './components/steps/1-ChooseDate.vue'
import ContactInfo from './components/steps/2-ContactInfo.vue'
import ExtraQuestions from '@/components/steps/3-ExtraQuestions'
import Confirmed from './components/steps/4-Confirmed.vue'

const routes = [
  {
    path: '/:customer/:slug',
    component: App,
    props (route) {
      const customer = route.params.customer
      const slug = route.params.slug

      return { customer, slug }
    },
    children: [
      {
        path: '',
        name: 'date',
        component: ChooseDate,
        meta: { step: 1 }
      },
      {
        path: 'info',
        name: 'info',
        component: ContactInfo,
        meta: { step: 2 },
        beforeEnter: checkSelection
      },
      {
        path: 'vragen',
        name: 'questions',
        component: ExtraQuestions,
        meta: { step: 3 },
        beforeEnter: checkSelection
      },
      {
        path: 'klaar',
        name: 'confirm',
        component: Confirmed,
        meta: { step: 4 },
        beforeEnter: checkSelection
      }
    ]
  }
]

function checkSelection (to, from, next) {
  if (!state.isSelectionMade) {
    return next({ ...to, name: 'date' })
  }

  next()
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
