export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Vragen"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beantwoord de volgende vragen, zodat we weten waarmee we je nog verder kunnen helpen."])},
        "questions": {
          1: (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunnen we je ook helpen met hypotheekadvies?"])},
          2: (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben je in het bezit van een eigen woning?"])},
          3: (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunnen we je helpen met de verkoop van je eigen woning?"])},
          4: (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil je op de hoogte gehouden worden van nieuw woningaanbod?"])}
        },
        "choice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je hebt gekozen voor ", _interpolate(_named("date")), " van ", _interpolate(_named("from")), " uur tot ", _interpolate(_named("to")), " uur."])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Final questions"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please answer the following questions, so we know what else we can help you with."])},
        "questions": {
          1: (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can we also assist you with mortgage advice?"])},
          2: (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you currently own your own home?"])},
          3: (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can we help you sell your own home?"])},
          4: (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to be kept informed of new housing offers?"])}
        },
        "choice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You’ve selected ", _interpolate(_named("date")), " from ", _interpolate(_named("from")), " to ", _interpolate(_named("to")), "."])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
      }
    }
  })
}