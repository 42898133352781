<template>
  <div>
    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
      <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
    </div>
    <div class="mt-3 text-center sm:mt-5">
      <h3 id="modal-title" class="text-lg leading-6 font-medium text-gray-900">
        {{ $t('title') }}
      </h3>
      <div class="mt-2">
        <p class="text-sm text-gray-500">
          {{ $t('thanks') }}<br>
          {{ $t('date', { date: date(state.viewingRequest.start, 'dddd D MMMM'), time: date(state.viewingRequest.start, 'H:mm') }) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/outline'
import state from '../../state'
import date from '../../util/date'

export default {
  components: {
    CheckIcon
  },
  setup () {
    return { state, date }
  }
}
</script>

<i18n lang="yaml">
nl:
  title: Bezichtiging ingepland!
  thanks: Bedankt voor je interesse in deze woning.
  date: We zien je graag a.s. {date} om {time} uur.
en:
  title: Viewing scheduled!
  thanks: Thank you for your interest in this property.
  date: We look forward to seeing you on {date} at {time}.
</i18n>
